<template>
    <router-view/>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name    : 'WebApp',
        computed: {
            ...mapGetters({
                userProfileHasLoaded: 'Account/getUserProfileLoaded',
            }),
        },
        watch   : {
            userProfileHasLoaded: {
                immediate: true,
                handler  : function (value) {
                    if (true === value) {
                        setTimeout(() => this.$router.push({name: 'app.dashboard'}), 250);
                    }
                },
            },
        },
    };
</script>
