<template>
    <div class="webApp">
        <transition
            name="fade"
            mode="out-in">
            <router-view v-if="userProfileHasLoaded"/>
        </transition>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name    : 'WebApp',
        metaInfo() {
            return {
                title: this.$t('META.ACCOUNT.TITLE'),
                meta : [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: 'noindex,nofollow',
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                userProfileHasLoaded: 'Account/getUserProfileLoaded',
            }),
        },
    };
</script>
